@use "../../../../../frontend/styles/tailwind.css";

// for showing the third element in each row full width in md, if any
@media (min-width: 768px) and (max-width:1023px) {
    .grid-container a:nth-child(3) {
        grid-column: 1 / 3;
    }
}

.lenkeboks-card {
    div:first-of-type {
        width: 100%;
        margin: 0 !important;
    }
}